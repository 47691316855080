import React, { useState, createContext, useEffect } from "react";
import InvitationCodePage from "./InvitationCodePage";
import SelectionPage from "./SelectionPage";
import FormPage from "./FormPage";
import QuestionPage from "./QuestionPage";
import ThankYouPage from "./ThankYouPage";
export const FormContext = createContext();

const Form = () => {
  const [userData, setUserData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);

  const [date0, setDate0] = useState(null);
  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);
  const dataValue = {
    userData,
    setUserData,
    setCurrentStep,
    currentStep,
    date0,
    setDate0,
    date1,
    setDate1,
    date2,
    setDate2,
  };
  return (
    <FormContext.Provider value={dataValue}>
      {currentStep === 0 ? (
        <InvitationCodePage />
      ) : currentStep === 1 ? (
        <SelectionPage />
      ) : currentStep === 2 ? (
        <FormPage />
      ) : currentStep === 3 ? (
        <QuestionPage />
      ) : currentStep === 4 ? (
        <ThankYouPage />
      ) : (
        <></>
      )}
    </FormContext.Provider>
  );
};

export default Form;
