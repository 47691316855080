import * as yup from "yup";

export const codeValidationSchema = yup.object().shape({
  code: yup.string().length(8, "fieldRequired"),
});

export const formValidationSchema = yup.object().shape(
  {
    name: yup.string().max(100).required("fieldRequired"),
    email: yup
      .string()
      .email("fieldRequired")
      .max(100)
      .required("fieldRequired"),
    phone: yup.string().required("fieldRequired").length(8, "fieldRequired"),
    sex: yup.string().when("sex", {
      is: true,
      then: yup.string().required("fieldRequired"),
    }),
    ig_name: yup.string().max(200).required("fieldRequired"),
    ig_url: yup.string().max(200).required("fieldRequired"),
    // fb_name: yup.string().max(200).required("fieldRequired"),
    // fb_url: yup.string().max(200).required("fieldRequired"),
    // date_of_birth_0: yup.string().when("date_of_birth_0", {
    //   is: true,
    //   then: yup.string().required("fieldRequired"),
    // }),
    // child_sex_0: yup.string().when("child_sex_0", {
    //   is: true,
    //   then: yup.string().required("fieldRequired"),
    // }),
    // date_of_birth_1: yup.string().when("date_of_birth_1", {
    //   is: true,
    //   then: yup.string().required("fieldRequired"),
    // }),
    // child_sex_1: yup.string().when("child_sex_1", {
    //   is: true,
    //   then: yup.string().required("fieldRequired").length(1, "fieldRequired"),
    // }),
    // date_of_birth_2: yup.string().when("date_of_birth_2", {
    //   is: true,
    //   then: yup.string().required("fieldRequired"),
    // }),
    // child_sex_2: yup.string().when("child_sex_2", {
    //   is: true,
    //   then: yup.string().required("fieldRequired").length(1, "fieldRequired"),
    // }),
  },
  [
    ["sex", "sex"],
    // ["date_of_birth_0", "date_of_birth_0"],
    // ["child_sex_0", "child_sex_0"],
    // ["date_of_birth_1", "date_of_birth_1"],
    // ["child_sex_1", "child_sex_1"],
    // ["date_of_birth_2", "date_of_birth_2"],
    // ["child_sex_2", "child_sex_2"],
  ]
);

export const enquireValidationSchema = yup.object().shape({
  email: yup.string().email("fieldRequired").max(100).required("fieldRequired"),
  phone: yup.string().required("fieldRequired").length(8, "fieldRequired"),
});

export const tncValidationSchema = yup.object().shape({
  tnc: yup.boolean().oneOf([true], "fieldRequired"),
});
