import React, { useState, useContext } from "react";
import "../../styles/form/SelectionPage.scss";
import { Trans, useTranslation } from "react-i18next";
import { FormContext } from "./Form";

const SelectionPage = () => {
  const { userData, setUserData, setCurrentStep, currentStep } =
    useContext(FormContext);

  const onSubmitData = (n) => {
    setUserData({ ...userData, member_type: n });
    setCurrentStep(2);
  };

  return (
    <div className="selection-page-container">
      <div className="selection-container">
        <div className="selection-half-container">
          <div className="select-image" onClick={() => onSubmitData(1)}>
            <img
              className="image"
              alt="left-image"
              src="/assets/img/joyKreator.png"
              draggable={false}
            />
          </div>
        </div>
        <div className="selection-half-container">
          <div className="select-image" onClick={() => onSubmitData(2)}>
            <img
              className="image"
              alt="right-image"
              src="/assets/img/businessKreator.png"
              draggable={false}
            />
          </div>
        </div>
      </div>{" "}
      <div className="image-container">
        <img
          className="image"
          alt="banner2"
          src="/assets/img/banner2.png"
          draggable={false}
        />
      </div>
    </div>
  );
};

export default SelectionPage;
