import "../styles/components/Loading.scss";

function Loading() {
  return (
    <div className="loading-container" id="loading">
      <img
        className="loading-image"
        alt="loading"
        src={process.env.PUBLIC_URL + "/assets/img/loading.gif"}
      ></img>
    </div>
  );
}

export default Loading;
