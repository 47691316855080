import React, { useState, createContext, useEffect } from "react";
import EnquirePage from "./EnquirePage";
import InformationPage from "./InformationPage";

export const EnquireContext = createContext();

const Enquire = () => {
  const [userData, setUserData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const dataValue = { userData, setUserData, currentStep, setCurrentStep };

  return (
    <EnquireContext.Provider value={dataValue}>
      {currentStep === 0 ? (
        <EnquirePage />
      ) : currentStep === 1 ? (
        <InformationPage />
      ) : (
        <></>
      )}
    </EnquireContext.Provider>
  );
};

export default Enquire;
