import React, { useContext, useEffect } from "react";
import "../../styles/enquire/InformationPage.scss";
import { Trans, useTranslation } from "react-i18next";
import { GlobalContext } from "../../App";
import { EnquireContext } from "./Enquire";

const InformationPage = () => {
  const { goEnquire, setGoEnquire } = useContext(GlobalContext);
  const { userData, setUserData, setCurrentStep, currentStep } =
    useContext(EnquireContext);
  console.log(currentStep);
  return (
    <div className="information-page-container">
      {" "}
      <div className="logo-container">
        <img className="logo" alt="logo" src="/assets/img/logo.png" />
      </div>
      <div className="information-container">
        <div className="information-text">
          <Trans i18nKey="enquire:information_info_text" />
        </div>
        <div className="enquire-row">
          <div className="label">
            <Trans i18nKey="enquire:information_name" />
          </div>
          <div className="info">{userData.name}</div>
        </div>
        <div className="enquire-row">
          <div className="label">
            <Trans i18nKey="enquire:information_email" />
          </div>
          <div className="info"> {userData.email}</div>
        </div>
        <div className="enquire-row">
          <div className="label">
            <Trans i18nKey="enquire:information_mobile" />
          </div>
          <div className="info"> {userData.mobile}</div>
        </div>
        <div className="enquire-row">
          <div className="label">
            <Trans i18nKey="enquire:information_points" />
          </div>
          <div className="info"> {userData.klook_points}</div>
        </div>
      </div>
      <div className="information-container">
        <div className="information-text">
          <Trans i18nKey="enquire:information_klook_point_text" />
        </div>
      </div>
      {/* <img
        className="image"
        alt="redemption"
        src="/assets/img/redemption.png"
        draggable={false}
      /> */}
      <div className="information-container">
        <div className="information-text">
          <Trans i18nKey="enquire:information_banner" />
        </div>
      </div>
      <br></br>
      <br></br>
      <button
        className="input-button"
        type="button"
        onClick={() => setGoEnquire(false)}
      >
        <Trans i18nKey="enquire:information_logout" />
      </button>
    </div>
  );
};

export default InformationPage;
