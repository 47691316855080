import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const resource = {
  en: {},
  tc: {},
};
i18n
  //ref https://medium.com/@a401120174/%E4%BD%BF%E7%94%A8-react-i18next-%E4%BE%86%E6%89%93%E9%80%A0%E5%A4%9A%E5%9C%8B%E8%AA%9E%E8%A8%80%E7%B6%B2%E7%AB%99%E5%90%A7-i18n-5b9c70c05d24
  // 使用 i18next-http-backend
  .use(Backend)
  // 將 i18next 傳入 react-i18next 裡面
  .use(initReactI18next)
  // 實例化 initReactI18next
  .init({
    resource,
    ns: ["form", "enquire"],
    // 當目前的語言檔找不到對應的字詞時，會用 fallbackLng (en) 作為預設語言
    fallbackLng: "tc",
    // 預設語言
    lng: "tc",
    interpolation: {
      // 是否要讓字詞 escaped 來防止 xss 攻擊，這裡因為 React.js 已經做了，就設成 false即可
      escapeValue: false,
    },
    backend: {
      //網頁載入時去下載語言檔的位置
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
